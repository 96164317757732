import React from "react"

export default function CallToAction(){
    return (
      <div className="relative mt-12 bg-deep-purple-accent-700 ">
        <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="flex mt-4 flex-col sm:text-center">
          <a href="/" className="mb-6 sm:mx-auto">
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-deep-purple-50">
              <svg
                className="w-10 h-10 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </a>
          <div className="max-w-xl mb-4 md:mx-auto sm:text-center lg:max-w-2xl md:mb-8">
            <h2 className="max-w-lg font-sans text-3xl font-semibold leading-tight  text-white sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="e77df901-b9d7-4b9b-822e-16b2d410795b"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">Have</span>
              </span>{' '}
               a project in mind? <span className="font-normal">Let's make it happen!</span>

            </h2>
          </div>
          <div className="mb-8">
            <a
              href="mailto:hello@appspirelabs.com"
              className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide transition duration-200 rounded shadow-md bg-white text-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
         
        </div>
      </div>
    );
  };