import React from "react"

export default function Content(){
    return (
      <div className="bg-amber-50">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              Why Us?
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="3071a3ad-db4a-4cbe-ac9a-47850b69e4b7"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#3071a3ad-db4a-4cbe-ac9a-47850b69e4b7)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative">Here's</span>
            </span>{' '}
             what makes Appspire Labs stand out
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
          We're not a typical software development company. Here’s what you can expect when you work with us
          </p>
        </div>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-5">
        <div className="relative md:col-span-2 lg:col-span-2">
          <img
            className="inset-0 object-cover object-bottom w-full lg:absolute h-full"
            src="../images/growth.svg"
            alt="business growth"
          />
        </div>
        <div className="grid gap-6 md:grid-cols-2 md:col-span-2 lg:col-span-3">
          <div className="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-deep-purple-50">
            <div className="flex items-center mb-1">
              <span className="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded bg-deep-purple-accent-400">
                1
              </span>
              <p className="text-lg font-semibold sm:text-base">
              Transparency
              </p>
            </div>
            <p className="text-sm text-gray-900">
            We're honest about our strengths, capabilities and limitations. If we aren't a great fit for your project, we'll say so. If we're a fantastic fit, we'll also say so!
            </p>
          </div>
          <div className="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-deep-purple-50">
            <div className="flex items-center mb-1">
              <span className="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-teal-900 rounded bg-teal-accent-400">
                2
              </span>
              <p className="text-lg font-semibold sm:text-base">
              Communication
              </p>
            </div>
            <p className="text-sm text-gray-900">
            You'll always know where your project stands. We'll stay in lock-step with you throughout the entire process, giving you ample opportunity to weigh in, provide direction and, if needed, change course.
            </p>
          </div>
          <div className="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-deep-purple-50">
            <div className="flex items-center mb-1">
              <span className="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded md:text-teal-900 bg-teal-accent-400">
                3
              </span>
              <p className="text-lg font-semibold sm:text-base">
              Quality
              </p>
            </div>
            <p className="text-sm text-gray-900">
            We're sticklers for quality, and it shows. But don't take our word for it. Check out some of our recent work to see for yourself. Check out some of our recent work to see for yourself.
            </p>
          </div>
          <div className="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-deep-purple-50">
            <div className="flex items-center mb-1">
              <span className="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-teal-900 rounded md:text-white bg-deep-purple-accent-400 md:bg-deep-purple-accent-400">
                4
              </span>
              <p className="text-lg font-semibold sm:text-base">Reliability</p>
            </div>
            <p className="text-sm text-gray-900">
            We'll set and meet realistic deadlines. We'll do our best to complete your project on-time and within budget. And we'll do reliably good work, too. Boring? Maybe. Worth it? Absolutely.
            </p>
          </div>
        </div>
        
      </div>
        </div>
      </div>
    );
  };