import React from "react"
import NavBar from '../components/NavBar'
import Hero from '../components/Hero'
import Services from '../components/Services'
import Footer from '../components/Footer'
import Process from '../components/Process'
import Content from '../components/Content'
import Clients from "../components/Clients"
import CallToAction from "../components/CallToAction"
import MetaData from "../components/MetaData"
export default function Home() {
  return <div >
      <MetaData title="Dedicated Mobile And Web App Development Team" description="Dedicated, Mobile & Web App Development Team For Your Success. Whether you're building a brand new app or updating an existing one, we are the tech team you need." 
      />
      <NavBar></NavBar>
      <Hero/>
      <Services/>
      <Content/>
      <Clients/>
      <CallToAction/>
      <Footer/>
  </div>
}
