import React from "react"

export default function Clients() {
  return (
    <div className="bg-white ">
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              Our Clients
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="3071a3ad-db4a-4cbe-ac9a-47850b69e4b7"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#3071a3ad-db4a-4cbe-ac9a-47850b69e4b7)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative">We</span>
            </span>{' '}
              are working with clients all over the world
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
          Our client are impressed with the quality of our work and love working with our team.
          </p>
        </div>
        <div class="flex flex-wrap py-8 ">
          <div class="w-full md:w-1/2 px-2 mb-4">
            <div class="bg-amber-50 rounded-lg border-amber-300 border  px-4 py-8 shadow">
              <p class="text-gray-800 text-base px-6 mb-5 leading-8">" An Amazing team that works hard to finish the project. The expected  result is just awesome! Trust them and you will not be disappointed. They are small but they are one of the best."</p>
              <div className="flex flex-row items-center space-x-3 px-4">
                <img class="w-16 h-16 rounded-full object-cover shadow" src="../images/klogen.jpeg" alt="avatar"></img>
                <div className="flex flex-col">
                <p class="text-gray-800 font-semibold">Klogen</p>
                <p class="text-gray-500 text-xs md:text-sm">Founder of Shamasic, France</p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 px-2 mb-4 ">
            <div class="bg-amber-50 rounded-lg border-amber-300 border  shadow  px-4 py-8">
              <p class="text-gray-800 text-base px-6 mb-5 leading-8">" They went above and beyond what was needed to develop mobile app prototype with Flutter. Good experience working with them - would do so again! "</p>
              <div className="flex flex-row items-center space-x-3 px-4">
                <img class="w-16 h-16 rounded-full object-cover shadow" src="../images/serge.jpeg" alt="avatar"></img>
                <div className="flex flex-col">
                <p class="text-gray-800 font-semibold">Serge Wilfried</p>
                <p class="text-gray-500 text-xs md:text-sm">CTO, DuniaPay, Canada</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap items-center justify-center mt-12">
          <div class="w-1/2 md:w-1/4 px-4 mb-4 md:mb-0 text-3xl font-semibold text-gray-700 hover:text-deep-purple-700 text-center">Shamasic</div>
          <div class="w-1/2 md:w-1/4 px-4 mb-4 md:mb-0 text-3xl font-semibold text-gray-700 hover:text-deep-purple-700 text-center">DuniaPay</div>
          <div class="w-1/2 md:w-1/4 px-4 mb-4 md:mb-0 text-3xl font-semibold text-gray-700 hover:text-deep-purple-700 text-center">Zunoo</div>
        </div>
      </div>
    </div>
  );
};
