import React from "react"

export default function Hero() {
  return (
    <div className="bg-deep-purple-50">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
        <div className="flex flex-col items-center justify-between lg:flex-row">
          <div className="mb-10 lg:w-1/2 lg:pr-5 lg:mb-0">
            <div className="max-w-xl mb-6">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                  Hello, We're the
              </p>
              </div>
              <h1 className="max-w-lg mb-6 font-sans text-3xl font-bold text-gray-900 sm:text-4xl sm:leading-tight">
                Dedicated, Mobile & Web
              <br className="hidden md:block" />
              App Development Team For{' '}
              <span className="relative px-1">
                <div className="absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-amber-200" />
                <span className="relative inline-block text-deep-purple-accent-700">
                  Your Success
                </span>
              </span>
              </h1>
              <p className="text-base text-gray-700 md:text-lg">
                Whether you're building a brand new app or updating an existing one, we are the tech team you need.
            </p>
            </div>
            <div className="flex flex-col items-center md:flex-row">
              <a
                href="mailto:hello@appspirelabs.com"
                className="inline-flex items-center justify-center w-full h-12 px-6 mb-3 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto md:mr-4 md:mb-0 bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              >
                <span className="mr-3">Get in Touch</span>
              </a>
            </div>
          </div>
          <div className="relative w-full lg:w-1/2">
            <img
              className="object-cover w-full h-full"
              src="../images/app_development.svg"
              alt="app development"
            />

          </div>
        </div>
      </div>
    </div>
  );
};